import App from 'next/app'
import { ThemeProvider } from 'styled-components'
import 'sanitize.css'
import '../styles/global.css'

const theme = {
  colors: {
    primary: '#0070f3'
  }
}

export default class Priestley extends App {
  render() {
    const { Component, pageProps } = this.props
    return (
      <ThemeProvider theme={theme}>
        <Component {...pageProps} />
      </ThemeProvider>
    )
  }
}
